import styled from "styled-components";

import LinkedInLogo from "../../../assets/linkedin.min.svg";

const StyledLinkedInLogo = styled(LinkedInLogo)`
  color: var(--black);
  transition: all 0.35s;
  width: 35px;

  @media only screen and (max-width: 400px) {
    width: 25px;
  }
`;

export default StyledLinkedInLogo;
