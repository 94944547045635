import styled from "styled-components";

const CustomizeThemeContainer = styled.div`
  cursor: auto;
  position: absolute;
  height: clamp(50px, 69vh, 390px);
  top: 0;
  left: 0;
  width: 300px;
  padding-bottom: 3rem;
  border-bottom-left-radius: 1rem;
  background: var(--section-background);
  backdrop-filter: blur(4px);
  box-shadow: ${({ open }) =>
    open ? "-2px 2px 8px rgba(0, 0, 0, 0.25)" : "none"};

  transform: translateX(90px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;
`;

export default CustomizeThemeContainer;
