import styled from "styled-components";

const StyledFooter = styled.footer`
  position: relative;
  bottom: 0;
  z-index: 0;
  width: 90%;
  height: 50px;

  color: var(--black);

  margin-top: 2rem;

  background: linear-gradient(
    270deg,
    var(--background-top),
    var(--background-bottom)
  );

  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  a {
    text-decoration: none;
    color: var(--primary-one-dark);
  }

  @media only screen and (max-width: 1024px) {
    height: 75px;
    flex-direction: column;
  }
`;

export default StyledFooter;
