import styled from "styled-components";

const AboutMe = styled.article`
  font-family: "Gilroy";
  font-size: 1.85rem;
  margin-top: 0.5rem;
  padding: 0 10rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    padding: 0 5rem;
  }

  @media only screen and (max-width: 530px) {
    font-size: 2rem;
    padding: 0 2.5rem;
  }
`;

export default AboutMe;
