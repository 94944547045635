import React, { useRef, useEffect } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import VanillaTilt from "vanilla-tilt";

import Logo from "../../assets/nbhlogo.min.svg";

import PillarBackground from "../components/PillarBackground";
import { Header } from "../components/Header";
import CustomizeShop from "../components/CustomizeShop";
import { GitHubLinkedIn } from "../components/GitHubLinkedIn";
import Info from "../components/Info";

const MainContainer = styled.main`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const LogoDiv = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  height: 80vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transform-style: preserve-3d;
  transform: perspective(5px);

  z-index: 50;
`;
const StyledLogo = styled(Logo)`
  height: 370px;
  filter: drop-shadow(3px 3px 4px var(--box-shadow-color));
  -webkit-filter: drop-shadow(3px 3px 4px var(--box-shadow-color));
  transform: translateZ(100px);

  z-index: 51;

  @media only screen and (max-width: 530px) {
    height: 200px;
  }
`;

const IndexPage = () => {
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, {
      scale: 1,
      speed: 1,
      max: 10,
      easing: "ease-in-out",
      gyroscope: true,
      gyroscopeMinAngleX: -45,
      gyroscopeMaxAngleX: 45,
      gyroscopeMinAngleY: -45,
      gyroscopeMaxAngleY: 45,
      gyroscopeSamples: 10,
    });
  }, []);

  return (
    <MainContainer>
      <Helmet>
        <title>Nathan Hotz</title>
      </Helmet>
      <PillarBackground />
      <Header />
      <GitHubLinkedIn />
      <CustomizeShop />
      <LogoDiv>
        <StyledContent
          ref={tilt}
          data-tilt
          data-tilt-full-page-listening
          data-tilt-reverse="true"
        >
          <StyledLogo />
        </StyledContent>
      </LogoDiv>
      <Info />
    </MainContainer>
  );
};

export default IndexPage;
