import styled from "styled-components";

const InfoContainer = styled.div`
  position: relative;
  width: clamp(350px, 46%, 700px);
  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;

  background: linear-gradient(
      135deg,
      var(--primary-one),
      rgba(256, 256, 256, 0.2),
      var(--primary-one-dark)
    ),
    linear-gradient(
      45deg,
      var(--primary-two-dark),
      rgba(256, 256, 256, 0.2),
      var(--primary-two)
    ),
    var(--black);

  box-shadow: 2px 2px 4px var(--box-shadow-color);
`;

export default InfoContainer;
