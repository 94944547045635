import React, { useEffect } from "react";
import styled from "styled-components";

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -9999;
  /* filter: blur(1px); */
  background: linear-gradient(
    145deg,
    var(--background-top),
    var(--background-bottom)
  );

  overflow-x: hidden;
  overflow-y: hidden;

  //hardware acceleration and remove flickering
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
`;

const Pillarvar = styled.div`
  position: absolute;
  height: 175vh;
  width: ${({ i }) =>
    i % 3 === 0
      ? Math.floor(Math.random() * 2) + 4
      : i % 3 === 1
      ? Math.floor(Math.random() * 10) + 4
      : Math.floor(Math.random() * 10) + 10}px;
  top: ${() => Math.floor(Math.random() * 60)}%;
  left: ${({ i }) => (i % 2 !== 0 ? i * 2.4 + 0 : 100 - i * 2.4)}%;
  background: ${(props) =>
    props.i % 5 === 1
      ? "linear-gradient(180deg, var(--primary-one), var(--primary-one-dark), var(--primary-two-dark) 47%, rgba(0,0,0,0) 70%)"
      : props.i % 5 === 2
      ? "linear-gradient(180deg, var(--primary-one-dark), var(--primary-one), var(--white) 47%, rgba(0,0,0,0) 70%)"
      : props.i % 5 === 3
      ? "linear-gradient(180deg, var(--primary-two), var(--primary-two-dark), var(--primary-one) 47%, rgba(0,0,0,0) 70%)"
      : props.i % 5 === 4
      ? "linear-gradient(180deg, var(--primary-one) , var(--primary-two), var(--primary-one-dark) 47%, rgba(0,0,0,0) 70%)"
      : "linear-gradient(180deg, var(--primary-two-dark), var(--white), var(--primary-two) 47%, rgba(0,0,0,0) 70%)"};
  border-radius: 50px;

  /* border: 0.25px solid var(--black); */

  z-index: ${(props) => props.i + -9998};

  animation-delay: ${({ i }) =>
    i % 3 === 0 ? 0 : i % 3 === 1 ? Math.random() * 3 : Math.random() * 5 + 2}s;
  animation: ${() => Math.random() * 45 + 15}s
    ${(props) => (props.i % 2 === 0 ? "moveUp2" : "moveUp1")} infinite;
  opacity: 1;

  @keyframes moveUp1 {
    0% {
      transform: rotate(45deg) translateY(75vh);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    75% {
      transform: rotate(45deg) translateY(-95vh);
      opacity: 1;
    }
    100% {
      transform: rotate(45deg) translateY(-95vh);
      opacity: 0;
    }
  }
  @keyframes moveUp2 {
    0% {
      transform: rotate(310deg) translateY(75vh);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    75% {
      transform: rotate(310deg) translateY(-95vh);
      opacity: 1;
    }
    100% {
      transform: rotate(310deg) translateY(-95vh);
      opacity: 0;
    }
  }

  //hardware acceleration and remove flickering
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
`;

const PillarBackground = () => {
  const pillars = [...new Array(30)];
  return (
    <Background>
      {pillars.map((_, index) => (
        <Pillarvar key={`${index}Pillar`} i={index} />
      ))}
    </Background>
  );
};

export default PillarBackground;
