import React from "react";
import styled from "styled-components";

import { StyledHeader } from "./styles";

const FirstName = styled.span`
  position: relative;
`;

const LastName = styled.span`
  position: relative;
  top: 25%;
  left: -4.76%;
  font-size: 93%;
`;

export const Header = () => {
  return (
    <StyledHeader>
      <FirstName>Nathan</FirstName>
      <LastName>Hotz</LastName>
    </StyledHeader>
  );
};
