import styled from "styled-components";

const GitHubLinkedInContainer = styled.nav`
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  height: 75px;
  padding: 1.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  z-index: 50;
`;

export default GitHubLinkedInContainer;
