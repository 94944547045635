import styled from "styled-components";

const StyledColorInput = styled.input`
  display: hidden;
  position: absolute;
  width: 0;
  opacity: 0;
  cursor: pointer;
`;

export default StyledColorInput;
