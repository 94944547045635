import React from "react";
import styled from "styled-components";

import {
  GitHubLinkedInContainer,
  StyledAnchorContainer,
  StyledGitHubLogo,
  StyledLinkedInLogo,
} from "./styles";

const StyledAnchor = styled.a``;

export const GitHubLinkedIn = () => {
  return (
    <GitHubLinkedInContainer>
      <StyledAnchor href="https://github.com/nathanHotz">
        <StyledAnchorContainer logo="github">
          <StyledGitHubLogo className="linkLogo" />
        </StyledAnchorContainer>
      </StyledAnchor>
      <StyledAnchor href="https://www.linkedin.com/in/nathan-hotz-511022173/">
        <StyledAnchorContainer logo="linkedIn">
          <StyledLinkedInLogo className="linkLogo" />
        </StyledAnchorContainer>
      </StyledAnchor>
    </GitHubLinkedInContainer>
  );
};
