import styled from "styled-components";

const AboutDiv = styled.div`
  position: relative;
  z-index: 0;
  width: 75%;
  height: 30vh;
  border-radius: 1rem;

  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0) 50%,
    var(--background-top) 70%
  );
`;

export default AboutDiv;
