import styled from "styled-components";

const StyledColorLabel = styled.label`
  height: 50px;
  width: 50px;
  background: ${({ color }) => `var(--${color})`};
  ${({ brDirection }) =>
    brDirection === "left"
      ? "border-top-left-radius: 50%;"
      : brDirection === "right"
      ? "border-top-right-radius: 50%;"
      : ""}
  ${({ brDirection }) =>
    brDirection === "left"
      ? "border-bottom-left-radius: 50%;"
      : brDirection === "right"
      ? "border-bottom-right-radius: 50%;"
      : "border-radius: none"}
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: 2px solid black;
  ${({ brDirection }) =>
    brDirection === "left"
      ? "border-right: none"
      : brDirection === "right"
      ? "border-left: none"
      : ""}
`;

export default StyledColorLabel;
