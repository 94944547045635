import styled from "styled-components";

const StyledAnchorContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;

  margin: 2rem;

  z-index: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 400px) {
    margin: 1rem;
  }

  &::before {
    content: "";
    height: 150px;
    width: 60px;
    position: absolute;
    z-index: -1;
    border-radius: 8rem;
    background: ${({ logo }) =>
      logo === "github"
        ? "linear-gradient(135deg,rgba(0, 0, 0, 0.9),var(--primary-one-dark),var(--primary-one))"
        : "linear-gradient(135deg,rgba(0, 0, 0, 0.9),var(--primary-two-dark),var(--primary-two))"};

    transform: translate(135px, -140px) rotate(45deg);

    transition: all 0.35s;
  }

  &:hover {
    &::before {
      content: "";
      height: 150px;
      width: 60px;
      position: fixed;
      z-index: -1;
      border-radius: 8rem;
      background: ${({ logo }) =>
        logo === "github"
          ? "linear-gradient(135deg,rgba(0, 0, 0, 0.9),var(--primary-one-dark),var(--primary-one))"
          : "linear-gradient(135deg,rgba(0, 0, 0, 0.9),var(--primary-two-dark),var(--primary-two))"};

      transform: translate(35px, -40px) rotate(45deg);
    }
  }

  &:hover {
    &:active .linkLogo {
      transform: translateY(0px);
    }
  }

  &:hover .linkLogo {
    color: var(--white);
    transform: translateY(-5px);
  }
`;

export default StyledAnchorContainer;
