import styled from "styled-components";

import GitHubLogo from "../../../assets/github.min.svg";

const StyledGitHubLogo = styled(GitHubLogo)`
  color: var(--black);
  transition: all 0.35s;
  width: 35px;

  @media only screen and (max-width: 400px) {
    width: 25px;
  }
`;

export default StyledGitHubLogo;
