import styled from "styled-components";

import Paint from "../../../assets/paint-board-and-brush.svg";

const StyledPaint = styled(Paint)`
  fill: var(--black);
  color: var(--black);
  transition: all 0.2s;
  height: 40px;
  width: 40px;
  margin-left: 7px;
`;

export default StyledPaint;
