import styled from "styled-components";

const AboutSubHeader = styled.h2`
  position: absolute;
  top: 0%;
  left: 0%;

  color: var(--background-top);

  font-size: 20rem;
  line-height: 0.73;
  margin: 0;
`;

export default AboutSubHeader;
