import styled from "styled-components";

const WavingHand = styled.span`
  display: inline-block;
  animation: infinite wave 5s ease-in-out;

  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }

    5% {
      transform: rotate(0deg);
    }

    10% {
      transform: rotate(20deg);
    }

    15% {
      transform: rotate(0deg);
    }

    20% {
      transform: rotate(20deg);
    }

    25% {
      transform: rotate(0deg);
    }
  }
`;

export default WavingHand;
