import styled from "styled-components";

const HideScrollContainer = styled.div`
  position: absolute;
  height: 1000px;
  width: 100%;
  overflow: hidden;
`;

export default HideScrollContainer;
