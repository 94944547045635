import React, { useState, useContext, useRef } from "react";
import styled from "styled-components";

import ThemeContext from "../context/ThemeContext";

import { useOutsideClick } from "../hooks/useOutsideClick";
import {
  CustomizeContainer,
  CustomizeThemeContainer,
  HideScrollContainer,
  StyledCancel,
  StyledColorContainer,
  StyledColorContainerHeader,
  StyledColorInput,
  StyledColorLabel,
  StyledPaint,
  StyledSectionLabel,
} from "./styles";

const TextIconShadowStyledSectionLabel = styled(StyledSectionLabel)`
  margin-left: 2.5rem;
`;

const TextIconShadowSpan = styled.span`
  margin: 0 0.4rem;
`;

const Divider = styled.div`
  width: 1rem;
`;

export const Customize = ({ onClickHandler, open, closeCustomize }) => {
  const [colors, setOneColor] = useContext(ThemeContext);

  const customizeRef = useRef(null);
  useOutsideClick(customizeRef, closeCustomize);

  const [primaryOne, setPrimaryOne] = useState("#00ffff");
  const [primaryOneDark, setPrimaryOneDark] = useState("#1b1464");
  const [primaryTwo, setPrimaryTwo] = useState("#ff00d5");
  const [primaryTwoDark, setPrimaryTwoDark] = useState("#3b3aee");
  const [white, setWhite] = useState("#ffffff");
  const [black, setBlack] = useState("black");
  const [backgroundTop, setBackgroundTop] = useState("#cfcfcf");
  const [backgroundBottom, setBackgroundBottom] = useState("#ffffff");
  const [boxShadowColor, setBoxShadowColor] = useState("#ffffff");

  return (
    <HideScrollContainer>
      <CustomizeContainer
        ref={customizeRef}
        onClick={onClickHandler}
        open={open}
      >
        {open ? (
          <StyledCancel className="customize-paint-icon" />
        ) : (
          <StyledPaint className="customize-paint-icon" />
        )}
        <CustomizeThemeContainer
          onClick={(e) => e.stopPropagation()}
          open={open}
        >
          <StyledColorContainerHeader>
            Customize the theme
          </StyledColorContainerHeader>
          <StyledSectionLabel>- Primary -</StyledSectionLabel>
          <StyledColorContainer>
            <StyledColorLabel
              for="primary"
              color="primary-one"
              brDirection="left"
            >
              <StyledColorInput
                id="primary"
                type="color"
                value={primaryOne}
                onChange={(e) => {
                  setPrimaryOne(e.target.value);
                  setOneColor({
                    ...colors,
                    primaryOne: e.target.value,
                  });

                  document
                    .getElementById("o")
                    .setAttribute("stop-color", e.target.value);
                  document
                    .getElementById("o1")
                    .setAttribute("stop-color", e.target.value);
                }}
              />
            </StyledColorLabel>
            <StyledColorLabel
              for="primary-one-dark"
              color="primary-one-dark"
              brDirection="right"
            >
              <StyledColorInput
                id="primary-one-dark"
                type="color"
                value={primaryOneDark}
                onChange={(e) => {
                  setPrimaryOneDark(e.target.value);
                  setOneColor({
                    ...colors,
                    primaryOneDark: e.target.value,
                  });
                  document
                    .getElementById("od")
                    .setAttribute("stop-color", e.target.value);
                  document
                    .getElementById("od1")
                    .setAttribute("stop-color", e.target.value);
                }}
              />
            </StyledColorLabel>
            <Divider />
            <StyledColorLabel
              for="primary-two"
              color="primary-two"
              brDirection="left"
            >
              <StyledColorInput
                id="primary-two"
                type="color"
                value={primaryTwo}
                onChange={(e) => {
                  setPrimaryTwo(e.target.value);
                  setOneColor({
                    ...colors,
                    primaryTwo: e.target.value,
                  });
                  document
                    .getElementById("t")
                    .setAttribute("stop-color", e.target.value);
                  document
                    .getElementById("t1")
                    .setAttribute("stop-color", e.target.value);
                }}
              />
            </StyledColorLabel>
            <StyledColorLabel
              for="primary-two-dark"
              color="primary-two-dark"
              brDirection="right"
            >
              <StyledColorInput
                id="primary-two-dark"
                type="color"
                value={primaryTwoDark}
                onChange={(e) => {
                  setPrimaryTwoDark(e.target.value);
                  setOneColor({
                    ...colors,
                    primaryTwoDark: e.target.value,
                  });
                  document
                    .getElementById("td")
                    .setAttribute("stop-color", e.target.value);
                  document
                    .getElementById("td1")
                    .setAttribute("stop-color", e.target.value);
                }}
              />
            </StyledColorLabel>
          </StyledColorContainer>
          <StyledSectionLabel>- Background -</StyledSectionLabel>
          <StyledColorContainer>
            <StyledColorLabel
              for="background-top"
              color="background-top"
              brDirection="left"
            >
              <StyledColorInput
                id="background-top"
                type="color"
                value={backgroundTop}
                onChange={(e) => {
                  setBackgroundTop(e.target.value);
                  setOneColor({
                    ...colors,
                    backgroundTop: e.target.value,
                  });
                }}
              />
            </StyledColorLabel>
            <StyledColorLabel
              for="background-bottom"
              color="background-bottom"
              brDirection="right"
            >
              <StyledColorInput
                id="background-bottom"
                type="color"
                value={backgroundBottom}
                onChange={(e) => {
                  setBackgroundBottom(e.target.value);
                  setOneColor({
                    ...colors,
                    backgroundBottom: e.target.value,
                  });
                }}
              />
            </StyledColorLabel>
          </StyledColorContainer>

          <TextIconShadowStyledSectionLabel>
            - <TextIconShadowSpan>Text</TextIconShadowSpan> -{" "}
            <TextIconShadowSpan>Icon</TextIconShadowSpan> -{" "}
            <TextIconShadowSpan>Shadow</TextIconShadowSpan> -
          </TextIconShadowStyledSectionLabel>
          <StyledColorContainer>
            <StyledColorLabel for="black" color="black" brDirection="left">
              <StyledColorInput
                id="black"
                type="color"
                value={black}
                onChange={(e) => {
                  setBlack(e.target.value);
                  setOneColor({
                    ...colors,
                    black: e.target.value,
                  });
                }}
              />
            </StyledColorLabel>
            <StyledColorLabel for="white" color="white" brDirection="mid">
              <StyledColorInput
                id="white"
                type="color"
                value={white}
                onChange={(e) => {
                  setWhite(e.target.value);
                  setOneColor({
                    ...colors,
                    white: e.target.value,
                  });
                }}
              />
            </StyledColorLabel>
            <StyledColorLabel
              for="box-shadow-color"
              color="box-shadow-color"
              brDirection="right"
            >
              <StyledColorInput
                id="box-shadow-color"
                type="color"
                value={boxShadowColor}
                onChange={(e) => {
                  setBoxShadowColor(e.target.value);
                  setOneColor({
                    ...colors,
                    boxShadowColor: e.target.value,
                  });
                }}
              />
            </StyledColorLabel>
          </StyledColorContainer>
        </CustomizeThemeContainer>
      </CustomizeContainer>
    </HideScrollContainer>
  );
};
