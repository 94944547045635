import styled from "styled-components";

import Cancel from "../../../assets/cancel.min.svg";

const StyledCancel = styled(Cancel)`
  color: var(--black);
  transition: all 0.2s;
  height: 30px;
  width: 30px;
  margin-left: 7px;
`;

export default StyledCancel;
