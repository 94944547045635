import styled from "styled-components";

const StyledHeader = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  font-size: 5rem;
  padding: 1.5rem;
  font-weight: 100;

  z-index: 50;

  border-bottom-right-radius: 1.5rem;

  color: var(--black);

  user-select: none;

  @media only screen and (max-width: 530px) {
    font-size: 4rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: 3rem;
  }
`;

export default StyledHeader;
