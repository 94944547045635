import React from "react";
import styled from "styled-components";
import { Footer } from "./Footer";

import { AboutMe, InfoContent, InfoContainer, WavingHand } from "./styles";

const Hello = styled.h2`
  margin: clamp(0.5rem, 25vh, 5.8rem), 0;
  font-size: 7rem;

  @media only screen and (max-width: 1024px) {
    font-size: 5rem;
    margin: clamp(0.5rem, 27vh, 7rem), 0;
  }
`;

const Exclamation = styled.span`
  font-size: 9rem;

  @media only screen and (max-width: 1024px) {
    font-size: 7rem;
  }
`;

const Info = () => {
  return (
    <InfoContainer>
      <InfoContent>
        <Hello>
          <WavingHand>&#128075;</WavingHand>
          {"  "}HELLO<Exclamation>!!</Exclamation>
        </Hello>
        <AboutMe>
          <p>
            My name is Nathan Hotz. I am a software developer based in Omaha,
            NE.
          </p>
          <p>
            Currently, I am working at DMSi Software as a software engineer and
            enjoy the challenges of bringing legacy applications to the web.
          </p>
          <p>
            During my freetime, I enjoy the world of HTML, CSS, JS, NodeJS and
            Golang and the ecosystems that accompany them.
          </p>
          <p>
            While I work on a portfolio of fun and interesting projects. Try out
            the theme customizer and see what fun themes you come up with.
          </p>
        </AboutMe>
        <Footer />
      </InfoContent>
    </InfoContainer>
  );
};

export default Info;
